// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.StatsGrid_root__zmKDu {
  /* padding: calc(var(--mantine-spacing-xl) * 1.5); */
}

.StatsGrid_value__49VqX {
  font-size: rem(24px);
  font-weight: 700;
  line-height: 1;
}

.StatsGrid_diff__PRxWj {
  line-height: 1;
  display: flex;
  align-items: center;
}

.StatsGrid_icon__wmCOo {
  color: light-dark(var(--mantine-color-gray-4), var(--mantine-color-dark-3));
}

.StatsGrid_title__9mRAB {
  font-weight: 700;
  text-transform: uppercase;
}`, "",{"version":3,"sources":["webpack://./src/views/pages/workspace/components/StatsGrid.module.css"],"names":[],"mappings":"AAAA;EACE,oDAAoD;AACtD;;AAEA;EACE,oBAAoB;EACpB,gBAAgB;EAChB,cAAc;AAChB;;AAEA;EACE,cAAc;EACd,aAAa;EACb,mBAAmB;AACrB;;AAEA;EACE,2EAA2E;AAC7E;;AAEA;EACE,gBAAgB;EAChB,yBAAyB;AAC3B","sourcesContent":[".root {\n  /* padding: calc(var(--mantine-spacing-xl) * 1.5); */\n}\n\n.value {\n  font-size: rem(24px);\n  font-weight: 700;\n  line-height: 1;\n}\n\n.diff {\n  line-height: 1;\n  display: flex;\n  align-items: center;\n}\n\n.icon {\n  color: light-dark(var(--mantine-color-gray-4), var(--mantine-color-dark-3));\n}\n\n.title {\n  font-weight: 700;\n  text-transform: uppercase;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `StatsGrid_root__zmKDu`,
	"value": `StatsGrid_value__49VqX`,
	"diff": `StatsGrid_diff__PRxWj`,
	"icon": `StatsGrid_icon__wmCOo`,
	"title": `StatsGrid_title__9mRAB`
};
export default ___CSS_LOADER_EXPORT___;
