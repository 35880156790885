const PageURL = {
  BASE: '/',
  PROFILE: '/profile',
  LOGOUT: '/logout',
  CLASSES: '/classes',
  CLASS_DETAIL: '/classes/:class_id',
  USERS: '/users',
  TESTS: '/tests',
  CREATE_CLASSES: '/classes/create',
  MAKE_TEST: '/classes/make-test/:test_id',
  MAKE_TEST_LISTENING: '/classes/make-test/:test_id/listening-test',
  MAKE_TEST_READING: '/classes/make-test/:test_id/reading-test',
  MAKE_TEST_WRITING: '/classes/make-test/:test_id/writing-test',
  MAKE_TEST_SPEAKING: '/classes/make-test/:test_id/speaking-test',
  QUESTIONS: '/questions',
  QUESTIONS_DETAIL: '/questions/:question_id',
  TESTS_DETAIL: '/tests/:test_id',
  MY_TESTS: '/my-tests',
  ERROR_404: '/error/404',
  ERROR_403: '/error/403',
  EXAM: '/exam/:test_id',
  WORKSPACE: '/workspace',
  OVERVIEW: '/overview',
  WORKSPACES: '/workspaces',
  LESSONS: '/lessons',
  LESSONS_DETAIL: '/lessons/:lesson_id',
};

export default PageURL;
